import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '~/utils/tw';
import { RiLoader4Fill } from '@remixicon/react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-sm text-base font-medium disabled:pointer-events-none focus:outline-none gap-1 leading-none transition duration-400',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground hover:bg-primary-hover hover:shadow-md active:border-primary disabled:bg-homy-gray-lighter disabled:border-homy-gray-lighter disabled:text-homy-gray-light',
        destructive:
          'bg-destructive text-white disabled:text-homy-gray-light hover:shadow-md',
        outline:
          'border border-input active:border-primary hover:shadow-md disabled:bg-homy-gray-lighter/25 disabled:border-homy-gray-lighter disabled:text-homy-gray-light',
        dashed:
          'border border-dashed active:border-primary hover:shadow-md disabled:bg-homy-gray-lighter/25 disabled:border-homy-gray-lighter disabled:text-homy-gray-light',
        ghost:
          'text-homy-gray-darker hover:text-homy-gray active:text-primary-hover disabled:text-homy-gray-light',
        'ghost-primary':
          'text-primary hover:text-primary-hover active:text-primary-hover disabled:text-homy-gray-light',
        'ghost-destructive':
          'text-destructive bg-transparent disabled:text-homy-gray-light',
        link: 'text-primary underline-offset-4 hover:underline hover:shadow-none disabled:text-homy-gray-light',
        navigation:
          'border-l-2 border-transparent hover:border-homy-gray-lighter rounded-lg text-homy-gray-darker text-base leading-none justify-start hover:shadow-none gap-4',
      },
      size: {
        default: 'h-10 px-4',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  stopPropagation?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      loading = false,
      asChild = false,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={loading}
        {...props}
      >
        {loading ? (
          <div className="flex items-center justify-center">
            <RiLoader4Fill className="animate-spin" />
          </div>
        ) : (
          children
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
